import React, { useEffect, useState } from "react";
import { Navbar, Nav, Button, Container } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../firebase"; // Asegúrate de importar tu configuración de Firebase
import Logo from './Logo';

const NavDash = () => {
  const [userName, setUserName] = useState("");
  const [lastAccessTime, setLastAccessTime] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    // Escuchar los cambios de autenticación
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // Si el usuario está autenticado
        setUserName(user.displayName || "Usuario"); // Si no tiene nombre, usa "Usuario"
        setLastAccessTime(user.metadata.lastSignInTime); // Hora de último acceso
      } else {
        // Redirigir al login si no está autenticado
        navigate("/");
      }
    });

    return () => unsubscribe(); // Limpia el listener al desmontar el componente
  }, [navigate]);

  const handleLogout = () => {
    auth.signOut()
      .then(() => {
        console.log("Cerraste sesión exitosamente.");
        navigate("/"); // Redirige al login después de cerrar sesión
      })
      .catch((error) => {
        console.error("Error al cerrar sesión: ", error);
      });
  };

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const calculateExpiryDate = (accessTime) => {
    if (!accessTime) return "N/A"; // Maneja el caso donde no hay hora disponible
    const accessDate = new Date(accessTime);
    accessDate.setMonth(accessDate.getMonth() + 6);
    return accessDate.toLocaleDateString("es-MX", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    });
  };

  const sessionExpiryDate = calculateExpiryDate(lastAccessTime);

  return (
    <Navbar expand="lg" className="navbar-dash bg-boot text-white" sticky="top" >
      <Container>
        {/* Logo y enlace al inicio */}        
        <Navbar.Brand as={Link} to="/dashboard" className="d-flex align-items-center">
          <Logo />
        </Navbar.Brand>

        {/* Toggle para dispositivos móviles */}
        <Navbar.Toggle aria-controls="navbar-dash-nav" />

        {/* Enlaces y contenido */}
        <Navbar.Collapse id="navbar-dash-nav">
          <Nav className="ms-auto d-flex align-items-center">
            {/* Mensaje de sesión */}
            <span className="nav-item text-center mx-auto">
              Debes iniciar sesión antes de <strong>{sessionExpiryDate}</strong>
            </span>

            {/* Nombre del usuario */}
            <span className="nav-item mx-3">
              Hola, <strong>{userName}</strong>
            </span>

            {/* Enlace a Contactos */}
            <Nav.Link onClick={() => scrollToSection("Contactos")} className="nav-item mx-3">
              Contactos
            </Nav.Link>

            {/* Botón de Cerrar sesión */}
            <Button variant="danger" onClick={handleLogout} className="nav-item mx-3">
              Cerrar sesión
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavDash;
