import React from 'react';
import { Inicio } from "./Inicio"; 
import Header from "./Header";
import { Features } from "./features"; // Importa los componentes necesarios
import { About } from "./about";
import { Services } from "./services";
import { Gallery } from "./gallery";
import { Testimonials } from "./testimonials";
import { Team } from "./Team";


import JsonData from "../data/data-en.json"; 
import './Login.css';

function Landing() {
  return (
    <div>
      <Header />
      <Inicio data={JsonData.Headers} />
      <Features data={JsonData.Features} /> {/* Renderiza los componentes y pasa los datos */}
      <About data={JsonData.About} />
      <Services data={JsonData.Services} />
      <Gallery data={JsonData.Gallery} />
      <Testimonials data={JsonData.Testimonials} />
      <Team data={JsonData.Team} />
   
 
      

    </div>
  );
}

export default Landing;