import React, { useRef, useLayoutEffect } from "react";
import { Canvas, useFrame } from "@react-three/fiber";

// Componente de estrella que parpadea
const Star = ({ position, scaleSpeed }) => {
  const ref = useRef();

  // Animación para simular parpadeo
  useFrame(({ clock }) => {
    const scale = 1 + Math.sin(clock.elapsedTime * scaleSpeed) * 0.3;
    ref.current.scale.set(scale, scale, scale);
  });

  return (
    <mesh ref={ref} position={position}>
      <sphereGeometry args={[0.1, 16, 16]} />
      <meshBasicMaterial color="#ffffff" />
    </mesh>
  );
};

// Componente para las estrellas
const Stars = ({ count = 500 }) => {
  const stars = Array.from({ length: count }).map(() => ({
    position: [
      (Math.random() - 0.5) * 100, // X
      (Math.random() - 0.5) * 100, // Y
      -Math.random() * 200,       // Z
    ],
    scaleSpeed: Math.random() * 2 + 1, // Velocidad de parpadeo
  }));

  return stars.map((star, index) => (
    <Star key={index} position={star.position} scaleSpeed={star.scaleSpeed} />
  ));
};

// Componente para las estrellas fugaces
const ShootingStar = ({ position, direction, speed }) => {
  const ref = useRef();

  // Animación de la estrella fugaz
  useFrame(({ clock }) => {
    ref.current.position.x += direction[0] * speed;
    ref.current.position.y += direction[1] * speed;
    ref.current.position.z += direction[2] * speed;

    // Si la estrella pasa de un rango específico, la reiniciamos
    if (ref.current.position.z > 5) {
      ref.current.position.set(position[0], position[1], position[2]);
    }
  });

  return (
    <mesh ref={ref} position={position}>
      <sphereGeometry args={[0.2, 16, 16]} />
      <meshBasicMaterial color="#ffffff" />
    </mesh>
  );
};

// Componente que genera varias estrellas fugaces
const ShootingStars = ({ count = 3 }) => {
  const stars = Array.from({ length: count }).map(() => ({
    position: [
      (Math.random() - 0.5) * 100, // X
      (Math.random() - 0.5) * 100, // Y
      -Math.random() * 200,       // Z
    ],
    direction: [
      Math.random() * 0.5 - 0.25,  // Dirección X
      Math.random() * 0.5 - 0.25,  // Dirección Y
      Math.random() * 0.5 + 0.2    // Dirección Z
    ],
    speed: Math.random() * 0.1 + 0.1, // Velocidad aleatoria
  }));

  return stars.map((star, index) => (
    <ShootingStar
      key={index}
      position={star.position}
      direction={star.direction}
      speed={star.speed}
    />
  ));
};

const StarrySky = () => {
  const canvasRef = useRef();
  const containerRef = useRef();

  useLayoutEffect(() => {
    if (canvasRef.current && containerRef.current) {
      const { width, height } = containerRef.current.getBoundingClientRect();
      canvasRef.current.width = width;
      canvasRef.current.height = height;
    }
  }, []);

  return (
    <div ref={containerRef} style={{ width: "100%", height: "500px", overflow: "hidden" }}> 
      <Canvas 
        ref={canvasRef}
        camera={{ position: [0, 0, 10] }}
      >
        <ambientLight intensity={0.5} />
        <Stars count={1000} />
        <ShootingStars count={5} /> 
      </Canvas>
    </div>
  );
};

export default StarrySky;