import React from "react";
import { Link } from 'react-router-dom'; 
import { Button } from 'react-bootstrap';
import StarrySky from "./StarrySky"; 
import './Login.css'; 

export const Inicio = (props) => {
  return (
    <div className="intro">
      {/* Fondo con las estrellas solo en la parte superior */}
      <div className="starry-sky">
        <StarrySky />
      </div>
      
      <div className="content">
        <header id="header">
          <div className="intro-text">
            <h1>
              {props.data ? props.data.title : "Loading"}
              <span></span>
            </h1>
            <p>{props.data ? props.data.paragraph : "Loading"}</p>
            <div className="button-wrapper">
              <Link to="/login">
                <Button className="login-button">Login</Button>
              </Link>
            </div>
          </div>
        </header>
      </div>
    </div>
  );
};
