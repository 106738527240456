import React from 'react';
import Logo from './Logo';
import Nav from './Nav';


function Navigation() {
  return (
    <header className="sticky-top z-4 py-2 px-5 d-flex align-items-center justify-content-between bg-white">
      <Logo />
      <Nav />
     
    </header>
  );
}

export default Navigation;