import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Navigation from "./Header";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CloseButton from 'react-bootstrap/CloseButton';
import { auth, createUserWithEmailAndPassword, sendEmailVerification, updateProfile } from '../firebase'; 
import Swal from 'sweetalert2'; 
import StarrySky from "./StarrySky"; 

function Register() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false); 
  const [error, setError] = useState('');

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email || !password || !name || !phone) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: 'Debe ingresar todos los datos.',
      });
      return; 
    }

    if (!termsAccepted) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: 'Debe aceptar los términos y condiciones.',
      });
      return; 
    }

    try {
      // Crea el usuario
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Actualiza el perfil para incluir el nombre
      await updateProfile(user, { displayName: name });

      // Envía el correo de verificación
      await sendEmailVerification(user);

      // Notifica al usuario del registro exitoso
      Swal.fire({
        icon: 'success',
        title: 'Registro exitoso',
        text: 'Te hemos enviado un correo electrónico para verificar tu cuenta.',
      });

      // Redirige al usuario a la página de inicio de sesión
      navigate('/login'); 

    } catch (err) {
      // Maneja los errores de registro 
      console.error("Error de registro:", err);
      let errorMessage = "Ocurrió un error durante el registro.";
      if (err.code === 'auth/email-already-in-use') {
        errorMessage = "El correo electrónico ya está en uso.";
      } else if (err.code === 'auth/weak-password') {
        errorMessage = "La contraseña es demasiado débil.";
      }
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: errorMessage,
      });
    }
  };

  return (
    <>
    <Navigation />
    <div className="vh-100 d-flex align-items-center justify-content-center login-container">
      <Container>
      <div className="starry-sky">
        <StarrySky />
      </div>
        <Row className="justify-content-center" >
          <Col xs={12} sm={8} md={6} lg={5} xl={4}>
            <div className="p-4 glass rounded">
              <div className="d-flex justify-content-end">
                <Link to="/">
                  <CloseButton variant="white" />
                </Link>
              </div>

              <h2 className="text-center my-3 text-lg font-medium">Register</h2>

              {error && <p className="text-danger text-center">{error}</p>} {/* Muestra el error si no acepta términos */}

              <Form onSubmit={handleSubmit} >
                <Form.Group className="mb-3" controlId="formBasicName">
                  <div className="input-group">
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      className="border-bottom"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <span className="input-group-text"><i className="fa-solid fa-user fa-lg"></i></span>
                  </div>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <div className="input-group">
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      className="border-bottom"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <span className="input-group-text"><i className="fa-solid fa-envelope fa-lg"></i></span>
                  </div>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <div className="input-group">
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      className="border-bottom"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <span className="input-group-text"><i className="fa-solid fa-lock fa-lg"></i></span>
                  </div>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPhone">
                  <div className="input-group">
                    <Form.Control
                      type="text"
                      placeholder="Phone"
                      className="border-bottom"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                    <span className="input-group-text"><i className="fa-solid fa-phone fa-lg"></i></span>
                  </div>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <Form.Check
                    type="checkbox"
                    label={
                      <span>
                        <a href="/terms" className="hover-underline text-decoration-none hover-underline text-black text-2xl">I agree to the terms</a>
                      </span>
                    }
                    onChange={(e) => setTermsAccepted(e.target.checked)} // Cambia el estado al marcar o desmarcar
                  />
                </Form.Group>

                <Button variant="dark" type="submit" className="w-100 my-3" disabled={!termsAccepted}> {/* Deshabilita el botón si no aceptan términos */}
                  Register
                </Button>

                <div className="text-center">
                  <Link to="/Login" className="hover-underline text-decoration-none hover-underline text-black">Already have an Account? / Login</Link>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  </>
  );
}

export default Register;
