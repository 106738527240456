import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Navigation from "./Header";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CloseButton from 'react-bootstrap/CloseButton';
import { auth } from '../firebase'; // Asegúrate de que esta ruta sea correcta
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import StarrySky from "./StarrySky"; 

import Swal from 'sweetalert2'; // Asegúrate de instalar sweetalert2

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [resetEmail, setResetEmail] = useState('');
  const [resetError, setResetError] = useState('');
  const [resetSuccess, setResetSuccess] = useState('');
  const [showResetForm, setShowResetForm] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      // Inicia sesión
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Verifica si el correo está confirmado
      if (!user.emailVerified) {
        setError("Debes confirmar tu correo electrónico antes de iniciar sesión.");
        Swal.fire({
          icon: 'error',
          title: 'Correo no verificado',
          text: 'Por favor, verifica tu correo electrónico antes de iniciar sesión.',
        });
        return;
      }

      // Si el correo está verificado, procede al dashboard
      Swal.fire({
        icon: 'success',
        title: 'Bienvenido',
        text: 'Inicio de sesión exitoso.',
      });
      navigate('/Dashboard');
    } catch (err) {
      setError("Email o contraseña incorrecta");
      console.error("Error de inicio de sesión:", err);
    }
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    if (!resetEmail) {
      setResetError("Por favor ingresa un correo electrónico.");
      return;
    }
    try {
      await sendPasswordResetEmail(auth, resetEmail);
      setResetSuccess("Te hemos enviado un correo para restablecer tu contraseña.");
      setResetEmail('');
      setResetError('');
    } catch (err) {
      setResetError("Hubo un error al enviar el correo, por favor verifica el correo ingresado.");
      console.error("Error al restablecer la contraseña:", err);
    }
  };

  const toggleResetForm = () => setShowResetForm(!showResetForm);

  return (
    <>
      <Navigation />
 
      <div className="vh-100 d-flex align-items-center justify-content-center login-container"> 
        <Container>
        <div className="starry-sky">
        <StarrySky />
      </div>
          <Row className="justify-content-center">
            <Col xs={12} sm={8} md={6} lg={5} xl={4}>
              <div className="p-4 glass rounded">
                <div className="d-flex justify-content-end">
                  <Link to="/">
                    <CloseButton variant="white" />
                  </Link>
                </div>

                <h2 className="text-center my-3 text-lg font-medium">Login</h2>

                <Form onSubmit={handleLogin}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <div className="input-group">
                      <Form.Control
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="border-bottom"
                      />
                      <span className="input-group-text">
                        <i className="fa-solid fa-envelope fa-lg"></i>
                      </span>
                    </div>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <div className="input-group">
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="border-bottom"
                      />
                      <span className="input-group-text">
                        <i className="fa-solid fa-lock fa-lg"></i>
                      </span>
                    </div>
                  </Form.Group>

                  {error && <p className="text-danger text-center">{error}</p>}

                  <div className="d-flex justify-content-end">
                    <Link to="#" onClick={toggleResetForm} className="text-decoration-none hover-underline text-black">
                      Forgot Password
                    </Link>
                  </div>

                  <Button variant="dark" type="submit" className="w-100 my-3">Login</Button>

                  <div className="text-center">
                    <Link to="/Register" className="text-decoration-none text-black">Don't have an Account? / Register</Link>
                  </div>
                </Form>

                {showResetForm && (
                  <div className="mt-3">
                    <h4 className="text-center">Restablecer Contraseña</h4>
                    <Form onSubmit={handlePasswordReset}>
                      <Form.Group className="mb-3" controlId="formResetEmail">
                        <div className="input-group">
                          <Form.Control
                            type="email"
                            placeholder="Ingresa tu correo electrónico"
                            value={resetEmail}
                            onChange={(e) => setResetEmail(e.target.value)}
                            className="border-bottom"
                          />
                          <span className="input-group-text">
                            <i className="fa-solid fa-envelope fa-lg"></i>
                          </span>
                        </div>
                      </Form.Group>

                      {resetError && <p className="text-danger text-center">{resetError}</p>}
                      {resetSuccess && <p className="text-success text-center">{resetSuccess}</p>}

                      <Button variant="dark" type="submit" className="w-100 my-3">Enviar correo</Button>
                    </Form>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Login;
