import React from 'react';
import { NavLink } from 'react-router-dom';
import { Navbar, Nav, Button } from 'react-bootstrap';
import { Menu } from 'lucide-react';
import { Link } from 'react-router-dom';  // Asegúrate de importar Link de react-router-dom

const NavLinks = () => {
  return (
    <Nav className="ms-auto">
      <Nav.Item>
        <NavLink className="nav-link" to="/">Quienes Somos</NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink className="nav-link" to="/blog">Nuestra Mision</NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink className="nav-link" to="/about">Servicios</NavLink>
      </Nav.Item>

      </Nav>
  );
};

const NavComponent = () => {
  return (
    <Navbar expand="md" className="navbar-custom"> {/* Usa clases de Bootstrap para la barra de navegación */}
      <div className="container-fluid">
        {/* Botón de toggle para pantallas pequeñas, gestionado automáticamente por React Bootstrap */}
        <Navbar.Toggle aria-controls="navbarNav">
          <Menu size={24} /> {/* Usando el ícono del toggle */}
        </Navbar.Toggle>

        {/* Contenedor de enlaces */}
        <Navbar.Collapse id="navbarNav">
          <NavLinks />
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

export default NavComponent;
