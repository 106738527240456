import { initializeApp } from 'firebase/app';
import { getAuth, createUserWithEmailAndPassword, sendPasswordResetEmail, sendEmailVerification, updateProfile } from 'firebase/auth';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore'; 

const firebaseConfig = {
  apiKey: "AIzaSyAm9fDmMqapGsJc0H2oZOG6TcwH6whrOn4",
  authDomain: "recordarthe-12e74.firebaseapp.com",
  projectId: "recordarthe-12e74",
  storageBucket: "recordarthe-12e74.appspot.com",
  messagingSenderId: "733261709137",
  appId: "1:733261709137:web:560adfac847d9453c05d70",
  measurementId: "G-FFLY7CL531"
};

// Inicializa Firebase
const app = initializeApp(firebaseConfig);

// Exporta la autenticación de Firebase
const auth = getAuth(app);

const db = getFirestore(app); 

// Inicializa y exporta Firebase Storage
const storage = getStorage(app); 

export { 
  auth, 
  createUserWithEmailAndPassword, 
  sendPasswordResetEmail, 
  sendEmailVerification,
  updateProfile,
  storage, // Exporta storage
  ref, 
  uploadBytesResumable, 
  getDownloadURL,
  db, // Exporta db
  collection, 
  query, 
  where, 
  getDocs
};
