
import React from 'react';
import background3 from '../images/background3.jpg';

function Logo() {
  return (
    <div className="d-flex align-items-center"> {/* Contenedor flex de Bootstrap */}
      <img src={background3} alt="Logo" className="img-fluid me-2" style={{ width: '64px', height: '64px' }} /> {/* Imagen con clases de Bootstrap */}
      <h3>Recordarthe</h3> 
    </div>
  );
}

export default Logo;