import React from 'react';

function ContactText(props) {
  return (
    <div id="contact-text">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="contact-text-content"> 
              <h2 className="text-center">Contactos de Confianza</h2>
            
              <p className="text-center"> 
                {props.data ? props.data.paragraph : "Cargando..."} 
              </p>
              <ul>
                {props.data 
                  ? props.data.Rules.map((rule, index) => (
                      <li key={index}>{rule}</li>
                    ))
                  : "Cargando..."}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactText;
