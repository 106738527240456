import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, addDoc, getDocs, updateDoc, doc, deleteDoc } from 'firebase/firestore';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Swal from 'sweetalert2';

function ContactManager() {
  const [contacts, setContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    isTrusted: false,
    videos: [],
    code: '', // To store the generated code
  });
  const [isEditing, setIsEditing] = useState(false);
  const [trustedCount, setTrustedCount] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');

  const auth = getAuth();
  const db = getFirestore();

  // Fetch contacts on component mount or refresh
  useEffect(() => {
    fetchContacts();
  }, []);

  const fetchContacts = async () => {
    try {
      const user = auth.currentUser;
      if (!user) return;

      const contactsRef = collection(db, 'users', user.uid, 'contacts');
      const querySnapshot = await getDocs(contactsRef);
      const fetchedContacts = [];
      querySnapshot.forEach((doc) => {
        fetchedContacts.push({ id: doc.id, ...doc.data() });
      });

      // Sort contacts to show trusted ones first
      const sortedContacts = fetchedContacts.sort((a, b) => b.isTrusted - a.isTrusted);
      setContacts(sortedContacts);

      // Update trusted contacts count
      const trustedContactsCount = fetchedContacts.filter((contact) => contact.isTrusted).length;
      setTrustedCount(trustedContactsCount);

      // If there are 2 contacts, show an alert (for max limit)
      if (trustedContactsCount >= 2) {
        setErrorMessage('Ya has registrado el máximo de 2 contactos de confianza');
      } else {
        setErrorMessage('');
      }
    } catch (error) {
      console.error('Error fetching contacts:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;

    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = auth.currentUser;
    if (!user) return;

    // Check if we are adding a new contact and if there are already 2 trusted contacts
    if (!isEditing && trustedCount >= 2 && formData.isTrusted) {
      // Show SweetAlert if trying to add a 3rd trusted contact
      Swal.fire({
        icon: 'warning',
        title: 'No es posible guardar más de 2 contactos de confianza',
        text: 'Ya has alcanzado el límite de contactos de confianza.',
      });
      return; // Prevent saving the contact
    }

    // If we are editing, check if we are trying to change a contact to trusted and there are already 2
    if (isEditing && formData.isTrusted && trustedCount >= 2) {
      Swal.fire({
        icon: 'warning',
        title: 'No es posible registrar más de 2 contactos de confianza',
        text: 'Ya tienes 2 contactos de confianza registrados.',
      });
      return; // Prevent saving the edited contact if trying to make it trusted
    }

    const contactsRef = collection(db, 'users', user.uid, 'contacts');

    try {
      // Generate code if the contact is trusted
      if (formData.isTrusted && !formData.code) {
        formData.code = generateCode(); // Generate the code for trusted contacts
      }

      if (isEditing) {
        // Update contact
        const contactDoc = doc(contactsRef, formData.id);
        await updateDoc(contactDoc, formData);
        setContacts(
          contacts.map((contact) =>
            contact.id === formData.id ? { ...contact, ...formData } : contact
          )
        );
      } else {
        // Add new contact
        const newContact = { 
          ...formData 
        };
        const docRef = await addDoc(contactsRef, newContact);
        setContacts([...contacts, { id: docRef.id, ...newContact }]);
      }

      resetForm();
      fetchContacts(); // Refresh contacts
    } catch (error) {
      console.error('Error saving contact:', error);
    }
  };

  const resetForm = () => {
    setFormData({
      name: '',
      email: '',
      phone: '',
      isTrusted: false,
      videos: [],
      code: '', // Reset the code field
    });
    setIsEditing(false);
    setSelectedContact(null);
  };

  const handleEdit = (contact) => {
    setFormData(contact);
    setIsEditing(true);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleDelete = async (contactId) => {
    const user = auth.currentUser;
    if (!user) return;

    const contactsRef = collection(db, 'users', user.uid, 'contacts');
    await deleteDoc(doc(contactsRef, contactId));
    setContacts(contacts.filter((contact) => contact.id !== contactId));
    fetchContacts(); // Refresh contacts
  };

  const toggleVideos = (contactId) => {
    setSelectedContact(selectedContact === contactId ? null : contactId);
  };

  const generateCode = () => {
    return Math.random().toString(36).substr(2, 8).toUpperCase();
  };

  return (
    <div className="container mt-4 pb-5">
      <div className="row">
        {/* Formulario */}
        <div className="col-md-6 ">
          <h4>{isEditing ? 'Editar Contacto' : 'Agregar Contacto'}</h4>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label className="form-label">Nombre Completo</label>
              <input
                type="text"
                className="form-control"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Correo Electrónico</label>
              <input
                type="email"
                className="form-control"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Teléfono</label>
              <input
                type="text"
                className="form-control"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
              />
            </div>
                        <div className="mb-3">
              <label className="form-check-label">
                <input
                  type="checkbox"
                  className="form-check-input p-2"
                  name="isTrusted"
                  checked={formData.isTrusted}
                  onChange={handleInputChange}
                />
                <span style={{ marginLeft: '10px' }}>Es contacto de confianza</span>
              </label>
            </div>

            {/* Botones */}
            <button type="submit" className="btn btn-primary " style={{ marginLeft: '10px' }}>
              {isEditing ? 'Actualizar Contacto' : 'Agregar Contacto'}
            </button>

            {isEditing && (
              <button
                type="button"
                className="btn btn-secondary ms-2 mt-3"
                onClick={resetForm}
              >
                Cancelar
              </button>
            )}
          </form>
        </div>

        {/* Lista de contactos */}
        <div className="col-md-6">
          <h4>Lista de Contactos</h4>
          {errorMessage && <div className="alert alert-warning">{errorMessage}</div>}
          {contacts.map((contact) => (
            <div key={contact.id} className="card mb-3">
              <div className="card-body">
                <h5 className="card-title">{contact.name}</h5>
                {contact.isTrusted && (
                  <p className="text-success">
                    Contacto de confianza - Código: {contact.code}
                  </p>
                )}
                <p className="card-text">Email: {contact.email}</p>
                <p className="card-text">Phone: {contact.phone}</p>
                <div className="d-flex align-items-center">
                  <button
                    className="btn btn-link btn-sm me-2"
                    onClick={() => handleEdit(contact)}
                  >
                    <i className="bi bi-pencil-square text-warning"></i>
                  </button>
                  <button
                    className="btn btn-link btn-sm"
                    onClick={() => handleDelete(contact.id)}
                  >
                    <i className="bi bi-trash text-danger"></i>
                  </button>
                </div>
                <div className="mt-2">
                  <button
                    className="btn btn-primary  btn-sm"
                    onClick={() => toggleVideos(contact.id)}
                  >
                    Ver Videos
                  </button>
                  {selectedContact === contact.id ? (
                    contact.videos.length > 0 ? (
                      <ul>
                        {contact.videos.map((video, index) => (
                          <li key={index}>{video}</li>
                        ))}
                      </ul>
                    ) : (
                      <p>No hay videos para este contacto</p>
                    )
                  ) : null}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ContactManager;
