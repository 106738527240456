import React, { useState, useEffect } from 'react';
import { 
  getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject 
} from 'firebase/storage';
import { 
  getFirestore, collection, addDoc, doc, getDoc, updateDoc, query, where, getDocs, deleteDoc 
} from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Swal from 'sweetalert2';
import ReactPlayer from 'react-player/lazy';

function VideoManager() {
  const [videos, setVideos] = useState([]);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState('https://www.youtube.com/watch?v=YAuzqX_tsB4'); // Video por defecto
  const [loading, setLoading] = useState(true);
  const [videoFile, setVideoFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [contacts, setContacts] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);

  // Fetch videos from Firestore
  const fetchVideos = async () => {
    setLoading(true);
    const auth = getAuth();
    const user = auth.currentUser;
    const db = getFirestore();

    if (!user) {
      console.log("No hay usuario autenticado.");
      return;
    }

    try {
      const videosRef = collection(db, 'videos');
      const q = query(videosRef, where('userId', '==', user.uid));
      const querySnapshot = await getDocs(q);
      const videosData = [];

      if (querySnapshot.empty) {
        console.log("No se encontraron videos para este usuario.");
      } else {
        querySnapshot.forEach((doc) => {
          videosData.push({ id: doc.id, ...doc.data() });
        });
      }

      setVideos(videosData);
    } catch (error) {
      console.error('Error al obtener los videos:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchContacts = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    const db = getFirestore();

    if (!user) {
      console.log("No hay usuario autenticado.");
      return;
    }

    try {
      const contactsRef = collection(db, 'users', user.uid, 'contacts');
      const querySnapshot = await getDocs(contactsRef);
      const contactsData = [];

      querySnapshot.forEach((doc) => {
        contactsData.push({ id: doc.id, ...doc.data() });
      });

      setContacts(contactsData);
    } catch (error) {
      console.error('Error al obtener los contactos:', error);
    }
  };

  const handleFileChange = (e) => {
    setVideoFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!videoFile) {
      Swal.fire('Error', 'Por favor, selecciona un archivo de video.', 'error');
      return;
    }

    const auth = getAuth();
    const user = auth.currentUser;
    const storage = getStorage();
    const storageRef = ref(storage, `videos/${user.uid}/${videoFile.name}`);
    const uploadTask = uploadBytesResumable(storageRef, videoFile);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(progress);
      },
      (error) => {
        console.error('Error al subir el video:', error);
      },
      async () => {
        try {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          const db = getFirestore();
          await addDoc(collection(db, 'videos'), {
            userId: user.uid,
            title: videoFile.name,
            url: downloadURL,
            storagePath: `videos/${user.uid}/${videoFile.name}`,
            uploadedAt: new Date(),
          });

          Swal.fire('Éxito', 'Video subido correctamente', 'success');
          fetchVideos();
        } catch (error) {
          console.error('Error al guardar la información del video en Firestore:', error);
        }
      }
    );
  };

  const handleDelete = async (videoId) => {
    const db = getFirestore();
    const storage = getStorage();
    const videoRef = doc(db, 'videos', videoId);

    try {
      const videoDoc = await getDoc(videoRef);
      const storagePath = videoDoc.data().storagePath;
      const storageRef = ref(storage, storagePath);

      await deleteObject(storageRef);
      await deleteDoc(videoRef);

      setVideos(videos.filter((video) => video.id !== videoId));
      Swal.fire('Éxito', 'Video eliminado correctamente', 'success');
    } catch (error) {
      console.error('Error al eliminar el video:', error);
      Swal.fire('Error', 'Hubo un problema al eliminar el video', 'error');
    }
  };

  const handleShare = (videoUrl) => {
    Swal.fire({
      title: 'Seleccionar Contactos para Compartir',
      text: 'Selecciona uno o más contactos con los que deseas compartir el video.',
      input: 'select',
      inputOptions: contacts.reduce((options, contact) => {
        options[contact.id] = contact.name;
        return options;
      }, {}),
      inputMultiple: true,
      showCancelButton: true,
      confirmButtonText: 'Compartir',
    }).then((result) => {
      if (result.isConfirmed) {
        const selected = result.value;
        if (selected.length > 0) {
          Swal.fire('Éxito', 'Video compartido correctamente.', 'success');
          console.log('Compartiendo a:', selected);  // Aquí puedes manejar el envío de videos
        } else {
          Swal.fire('Advertencia', 'Debes seleccionar al menos un contacto.', 'warning');
        }
      }
    });
  };

  const handleRename = async (video) => {
    const { value: newTitle } = await Swal.fire({
      title: 'Renombrar Video',
      input: 'text',
      inputLabel: 'Nuevo título',
      inputValue: video.title,
      showCancelButton: true,
      confirmButtonText: 'Guardar',
      cancelButtonText: 'Cancelar',
      inputValidator: (value) => {
        if (!value) {
          return '¡El título no puede estar vacío!';
        }
      },
    });

    if (newTitle) {
      const db = getFirestore();
      const videoRef = doc(db, 'videos', video.id);

      try {
        await updateDoc(videoRef, { title: newTitle });
        setVideos(videos.map((v) =>
          v.id === video.id ? { ...v, title: newTitle } : v
        ));
        Swal.fire('Éxito', 'Video renombrado correctamente', 'success');
      } catch (error) {
        console.error('Error al renombrar el video:', error);
        Swal.fire('Error', 'Hubo un problema al renombrar el video', 'error');
      }
    }
  };

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchVideos();
        fetchContacts();  // Cargar contactos al iniciar sesión
      } else {
        console.log("No hay usuario autenticado.");
        setVideos([]);
        setSelectedVideoUrl('https://www.youtube.com/watch?v=YAuzqX_tsB4'); // Volver al video por defecto
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-7 mx-left">
          <ReactPlayer
            url={selectedVideoUrl}
            controls={true}
            playing={true}
            width="100%"
            height="500px"
          />
        </div>
        <div className="col-md-4">
          <div>
            <input type="file" accept="video/*" onChange={handleFileChange} />
            {videoFile && (
              <button onClick={handleUpload} disabled={uploadProgress > 0}>
                Subir Video
              </button>
            )}
            {uploadProgress > 0 && (
              <div>
                <p>{`Progreso: ${Math.round(uploadProgress)}%`}</p>
              </div>
            )}
          </div>

          <div className="mt-4">
            {loading ? (
              <p>Cargando videos...</p>
            ) : (
              <ul className="list-unstyled">
                {videos.length > 0 ? (
                  videos.map((video) => (
                    <li key={video.id} className="media mb-3 d-flex align-items-center justify-content-between">
                      <div
                        className="mr-3"
                        style={{ cursor: 'pointer' }}
                        onClick={() => setSelectedVideoUrl(video.url)}
                      >
                        {video.title}
                      </div>
                      <div>
                        <button className="btn btn-link p-2" onClick={() => handleShare(video.url)}>
                          <i className="bi bi-share text-primary" style={{ fontSize: '20px' }}></i>
                        </button>
                        <button className="btn btn-link p-2 ml-3" onClick={() => handleRename(video)}>
                          <i className="bi bi-pencil text-warning" style={{ fontSize: '20px' }}></i>
                        </button>
                        <button className="btn btn-link p-2 ml-3" onClick={() => handleDelete(video.id)}>
                          <i className="bi bi-trash text-danger" style={{ fontSize: '20px' }}></i>
                        </button>
                      </div>
                    </li>
                  ))
                ) : (
                  <p>No tienes videos registrados</p>
                )}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default VideoManager;
