import React, { useEffect, useState } from 'react';
import NavDash from './NavDash';
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import VideoManager from './VideoManager';
import ContactManager from './ContactManager';
import ContactText from './ContactText';

import data from "../data/data-en.json"; 

function Dashboard() {
  const navigate = useNavigate();
  const [contactData, setContactData] = useState(null); // Definimos el estado para los datos

  // useEffect para manejar la autenticación y cargar los datos
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) {
        navigate('/Login');
      } else {
        setContactData(data['contact-text']); // Cargar los datos del JSON cuando el usuario está autenticado
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  // Verificamos si los datos están cargados antes de renderizar ContactText
  if (!contactData) {
    return <div>Cargando...</div>; // Muestra un mensaje mientras se cargan los datos
  }

  return (
    <div>
      <NavDash />
  
      <div className="container-fluid Dashboard bg-black">
  <div className="row">
    {/* Columna para VideoManager */}
    <div className="col-md-12">
      <div className="card video-manager-card">
        <div className="card-body">
          <VideoManager />
        </div>
      </div>
    </div>

    {/* Columna para ContactText */}
    <div className="col-md-12" id="Contactos">
      <div className="card video-manager-card">
        <div className="card-body">
          <ContactText data={contactData} />
        </div>
      </div>

      {/* Columna para ContactManager */}
      <div className="col-md-12">
        <div className="card contact-manager-card">
          <div className="card-body">
            <ContactManager />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



    </div>
  );
}

export default Dashboard;
